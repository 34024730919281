import React, { useState } from "react";
import { Button, Modal, Upload } from "antd";
import { useTranslation } from "react-i18next";
import { UploadOutlined } from '@ant-design/icons';

const OrderDetailedProfessional = ({record}) => {
  const { t } = useTranslation();
 console.log(record,"record bro")
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  
  const formatCreatedAt = (createdAt) => {
    const date = new Date(createdAt);
    return date.toLocaleString(); 
  };

  const props = {
    beforeUpload: (file) => {
      console.log('Before uploading:', file);
      return true;
    },
    onChange: (info) => {
      console.log('File change:', info.file, info.fileList);
    },
  };
 console.log(record,"recofr ddsdsd")
  return (
    <>
      <Button
        type="primary"
        className="text-white bg-blue-500"
        onClick={showModal}
      >
        {t("Detail")}
      </Button>
      <Modal
        centered
        open={isModalOpen}
        onOk={handleOk}
        okType="default"
        okText="Ok"
        cancelText="cancel"
        onCancel={handleCancel}
      >
        <div className="flex space-y-6 flex-col ">
          <div>
            <p className="font-bold text-xl text-[#001529]">
              {t("Order Information")}
            </p>
          </div>
          <div className="flex flex-col justify-center text-[#001529] text-lg ">
          <p>{t("Order Number")} : {record?.jobId.jobId}</p>
            {/* <p>{t("Client Local Time")} : 12:09 PM (GMT-5)</p> */}
            <p>{t("Priority")} : {record?.jobId.priority}</p>
            {/* <p>{t("Client Name")} : {selectedOrder?.client}</p> */}
            <p>{t("Title")} : {record?.jobId.title}</p>
            <p>{t("Client Name")} : {record?.jobPoster?.name}</p>
            <p>{t("Price")} : ${record?.jobId.price}</p> 
            <p>{t("Job Posted At")} : {formatCreatedAt(record?.createdAt)}</p>   
             {
              record.status === "ACTIVE" &&  <Upload {...props} className="relative top-10">
              <Button icon={<UploadOutlined />}>Upload Your Work here</Button>
            </Upload>
             }
          </div>
        </div>
      </Modal>
    </>
  );
};
export default OrderDetailedProfessional;
