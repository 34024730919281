import React from "react";
import { Form, Input, Button, Select } from "antd";
import { useTranslation } from "react-i18next";

// const { Option } = Select;
const Withdraw = () => {
  const { t } = useTranslation();

  const onFinish = (values) => {
    console.log("Withdrawal details:", values);
  };
  return (
    <div className="flex justify-center">
      <div className="bg-white p-8 rounded shadow-md lg:w-[50%]">
        <div>
          <div>
            <h2 className="text-xl font-semibold mb-4">
              Available Balance : 120*
            </h2>
          </div>
        </div>
        <h2 className="text-xl font-semibold mb-4">{t("Withdraw Funds")}</h2>
        <Form
          name="withdrawalForm"
          onFinish={onFinish}
          initialValues={{ currency: "usd" }}
        >
          <Form.Item
            label={t("Amount")}
            name="amount"
            rules={[
              {
                required: true,
                message: t("Please enter the withdrawal amount"),
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>

          {/* <Form.Item label={t("Currency")} name="currency">
            <Select>
              <Option value="usd">USD</Option>
              <Option value="eur">EUR</Option>
              <Option value="gbp">GBP</Option>
              <Option value="inr">INR</Option>
            </Select>
          </Form.Item> */}

          <Form.Item>
            <Button
              type="primary"
              style={{ backgroundColor: "#1677ff" }}
              htmlType="submit"
              className="w-full"
            >
              {t("Withdraw")}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Withdraw;
