import { BrowserRouter, Routes, Route } from "react-router-dom";
import Signup from "./views/auth/Signup";
import Login from "./views/auth/Login";
import ResetPassword from "./views/auth/ResetPassword";
import Landing from "./components/Landing/Landing";
import Client from "./components/Client/Client";

// Test Imports
import NewDeals from "./components/Professional/NewDeals";
import Orders from "./components/Client/Orders/Orders";
import UserOrders from "./components/Professional/Orders/Orders";

import UserProfile from "./components/UserProfile";
import JobTags from "./components/Professional/JobTags";
import Payout from "./components/Professional/Payout";
import Balance from "./components/Professional/Balance/Balance";
import Admin from "./components/Admin/Admin";
import Allusers from "./components/Admin/Alluser";
import AllOrders from "./components/Admin/Orders";
import UserDetails from "./components/Admin/Singleuser";
import Order from "./components/Admin/Order";
import AppliedJobs from "./components/Admin/AppliedJobs";
import ProtectedRoute from "./components/ProtectedRoute";
// import SimpleChat from "../src/components/Professional/Chat";
import SimpleChatNew from "../src/components/Professional/ChatNew";
import SidebarPro from "./components/Professional/SidebarPro";
import Settings from "./components/Settings";
import ChatDetailes from "./components/Professional/ChatDetailes";
import PostProject from "./components/Client/PostProject/PostProject";
import Tags from "./components/Admin/Tags";
import RecommendedJobs from "./components/Professional/RecommendedJobs";
import Dashboard from "./components/Admin/Dashboard";
import NotFound from "./components/ErrorPage";
import Complaints from "./components/Admin/Complaints";
import ComplaintDetails from "./components/Admin/ComplaintsTables/ComplaintDetails";
import PaymentSuccessPage from "./components/Professional/PaymentSuccessPage";
import ComplaintsProfessional from "./components/Professional/Complaints";
import ComplaintsClient from "./components/Client/Complaints";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/" element={<Landing />} />

          <Route path="/app/client" element={<Client />}>
            <Route
              path="postproject"
              element={
                <ProtectedRoute Cmp={PostProject} text={"Post a project"} />
              }
            />
            <Route
              path="orders"
              element={<ProtectedRoute Cmp={Orders} text={"see all orders"} />}
            />
             <Route
              path="complaints"
              element={<ProtectedRoute Cmp={ComplaintsClient} text={"see all complaints"} />}
            />
            <Route
              path="profile"
              element={
                <ProtectedRoute Cmp={UserProfile} text={"see full profile"} />
              }
            />
            <Route path="settings" element={<Settings />} />
            <Route path="success" element={<PaymentSuccessPage />} />
            <Route path="message" element={<SimpleChatNew />} />
            <Route path="message/:username" element={<ChatDetailes />} />
          </Route>

          {/* Test Routes */}
          <Route path="/app/professional" element={<SidebarPro />}>
            <Route path="newdeals" element={<NewDeals />} />
            <Route
              path="orders"
              element={
                <ProtectedRoute Cmp={UserOrders} text={"see all orders"} />
              }
            />
               <Route
              path="complaints"
              element={
                <ProtectedRoute Cmp={ComplaintsProfessional} text={"see all complaints"} />
              }
            />
            <Route
              path="recommended-orders"
              element={
                <ProtectedRoute
                  Cmp={RecommendedJobs}
                  text={"see recommended jobs"}
                />
              }
            />
            <Route
              path="profile"
              element={
                <ProtectedRoute Cmp={UserProfile} text={"see full profile"} />
              }
            />
            <Route
              path="my-tags"
              element={<ProtectedRoute Cmp={JobTags} text={"see Tags"} />}
            />
            <Route
              path="payout"
              element={
                <ProtectedRoute Cmp={Payout} text={"see Payouts history"} />
              }
            />
            <Route
              path="balance"
              element={<ProtectedRoute Cmp={Balance} text={"see Balance"} />}
            />
            <Route
              path="message"
              element={
                <ProtectedRoute Cmp={SimpleChatNew} text={"see messages"} />
              }
            />
            <Route path="message/:username" element={<ChatDetailes />} />
            <Route path="settings" element={<Settings />} />
          </Route>

          <Route path="/support" element={<Admin />}>
            <Route path="dashboard" element={<Dashboard />} />

            <Route path="tags" element={<Tags />} />
            <Route path="user/:id" element={<UserDetails />} />
            <Route path="orders/:id" element={<Order />} />
            <Route path="allusers" element={<Allusers />} />
            <Route path="complaints" element={<Complaints />} />
            <Route
              path="complaints/:user/:name/:id"
              element={<ComplaintDetails />}
            />
            <Route path="orders" element={<AllOrders />} />
            <Route path="applied-order" element={<AppliedJobs />} />
          </Route>
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
