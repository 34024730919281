import React, { useState } from "react";
import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";
import UserInfo from "../UserInfo";

const OrderDetailButton = ({ record }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
    console.log(record, "modallll");
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const formatCreatedAt = (createdAt) => {
    const date = new Date(createdAt);
    return date.toLocaleString();
  };

  return (
    <>
      <Button
        type="primary"
        className="text-white bg-blue-500"
        onClick={showModal}
      >
        {t("Detail")}
      </Button>
      <Modal
        centered
        open={isModalOpen}
        onOk={handleOk}
        okType="default"
        okText="Ok"
        cancelText="cancel"
        onCancel={handleCancel}
      >
        <div className="flex space-y-6 flex-col ">
          <div>
            <p className="font-bold text-xl text-[#001529]">
              {t("Order Information")}
            </p>
          </div>
          <div className="flex flex-col justify-center text-[#001529] text-lg ">
            <p>
              {t("Order Number")} : {record?.jobId.jobId}
            </p>
            <p>
              {t("Priority")} : {record?.jobId.priority}
            </p>
            <p>
              {t("Title")} : {record?.jobId.title}
            </p>
            <p>
              {t("User Name")} : {record?.userId?.name}
            </p>
            {/* <p>
              {t("Freelancer Price")} : ${record?.price}
            </p> */}
            <p>
              {t("Price")} : ${record?.jobId.price}
            </p>
            <p>
              {t("Job Posted At")} : {formatCreatedAt(record?.createdAt)}
            </p>
            <UserInfo User={record.userId} />
          </div>
        </div>
      </Modal>
    </>
  );
};
export default OrderDetailButton;
