import {UserOutlined} from "@ant-design/icons";
import {Avatar} from "antd";
import React, {useContext, useEffect, useRef, useState} from "react";
import {IoSend, IoArrowBack} from "react-icons/io5";
import {UserContext} from "../../Context/UserContext";
import {MdOutlineEmojiEmotions} from "react-icons/md";

import {Link} from "react-router-dom";
import EmojiPicker from "emoji-picker-react";
import {SocketContext} from "../../Context/SocketContext";
import {SocketMessageTypes} from "../../utils/constants";

const Chat = () => {
    const [messageInput, setMessageInput] = useState("");
    const [chatMessages, setChatMessages] = useState([]);
    const {User} = useContext(UserContext);
    const {sendSocketMessage, newMessage} = useContext(SocketContext);
    const [showPicker, setShowPicker] = useState(false);
    const [chatRooms, setChatRooms] = useState([]);
    const [selectedChatRooms, setSelectedChatRooms] = useState();
    const [loggedInUser, setLoggedInUser] = useState();
    const chatRef = useRef();

    useEffect(() => {
        if (User) {
            setLoggedInUser(User.data);
        }
    }, [User])


    useEffect(() => {
        const userChats = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/chatrooms`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
            });
            const data = await response.json();
            if (data.success) {
                setChatRooms(data.chatRooms.map(rooms => {
                    let users = rooms.participants.find(t => t._id !== loggedInUser._id);
                    return {
                        ...rooms,
                        displayUser: users ? users : {},
                    }
                }).sort((a, b) => {
                    // Handle cases where lastChat might not be available
                    const aDate = a.lastChat?.createdAt ? new Date(a.lastChat.createdAt) : 0;
                    const bDate = b.lastChat?.createdAt ? new Date(b.lastChat.createdAt) : 0;

                    return bDate - aDate; // Sort descending: latest to be on top
                }));
            } else {
                console.log("Code is wrong");
            }
        };
        if (loggedInUser) {
            userChats();
        }
    }, [loggedInUser]);

    useEffect(() => {
        if (newMessage) {
            switch (newMessage.type) {
                case SocketMessageTypes.LOAD_MESSAGE:
                    setChatMessages(newMessage.data.message);
                    break;
                case SocketMessageTypes.NEW_MESSAGE:
                    if (selectedChatRooms && selectedChatRooms._id === newMessage.data.roomId) {
                        const sentMessage = {
                            type: SocketMessageTypes.MARK_AS_READ,
                            data: {
                                messageIds: [newMessage.data._id]
                            }
                        };

                        sendSocketMessage(sentMessage);
                        setChatMessages((prevMessages) => [...prevMessages, newMessage.data]);
                    }
                    let lastMessage = newMessage.data;
                    let allChatRooms = [...chatRooms];
                    allChatRooms = allChatRooms.map(item => {
                        let newItem = {...item};
                        if (item._id === lastMessage.roomId) {
                            newItem.lastChat = lastMessage;
                            if (selectedChatRooms?._id !== lastMessage.roomId) {
                                newItem.totalUnread += 1;
                            }
                        }
                        return {...newItem};
                    })
                    setChatRooms([...allChatRooms.sort((a, b) => {
                        // Handle cases where lastChat might not be available
                        const aDate = a.lastChat?.createdAt ? new Date(a.lastChat.createdAt) : 0;
                        const bDate = b.lastChat?.createdAt ? new Date(b.lastChat.createdAt) : 0;

                        return bDate - aDate; // Sort descending: latest to be on top
                    })]);
                    break;
                default:
                    break;
            }
        }
    }, [newMessage])

    useEffect(() => {
        if (chatMessages) {
            if (chatRef.current) {
                chatRef.current.scrollTop = chatRef.current.scrollHeight;
            }
        }
    }, [chatMessages, chatRef])

    const openChat = (room) => {
        if (selectedChatRooms && selectedChatRooms._id === room._id) {
            setSelectedChatRooms(null);
        } else {
            setSelectedChatRooms(room);
        }
        sendSocketMessage({
            type: SocketMessageTypes.LOAD_MESSAGE,
            data: {
                roomId: room._id
            }
        })
        setChatMessages([]);
        let allChatRooms = [...chatRooms];
        allChatRooms = allChatRooms.map(item => {
            if (item._id === room._id) {
                item.totalUnread = 0;
            }
            return {...item};
        })
        setChatRooms([...allChatRooms]);
    };

    const onEmojiClick = (event, emojiobj) => {
        setMessageInput(messageInput + emojiobj.target.currentSrc);
        setShowPicker(false);
    };

    const sendMessage = () => {
        if (!selectedChatRooms || messageInput.trim() === "") {
            return;
        }

        const sentMessage = {
            type: SocketMessageTypes.NEW_MESSAGE,
            data: {
                roomId: selectedChatRooms._id,
                user: loggedInUser._id,
                message: messageInput,
            }
        };

        sendSocketMessage(sentMessage);

        setChatMessages((prevMessages) => [...prevMessages, {
            createdAt: new Date().toISOString(),
            message: messageInput,
            senderId: loggedInUser._id
        }]);

        let allChatRooms = [...chatRooms];
        allChatRooms = allChatRooms.map(item => {
            if (item._id === selectedChatRooms._id) {
                item.lastChat = {
                    createdAt: new Date().toISOString(),
                    message: messageInput,
                    senderId: loggedInUser._id
                };
            }
            return {...item};
        })
        setChatRooms([...allChatRooms]);

        setMessageInput("");
    };

    return (
        <div className="bg-gray-100 h-screen flex items-center justify-center">
            {chatRooms?.length !== 0 ? (
                <>
                    {User ?
                        <div
                            className="w-full h-full bg-white shadow-md overflow-hidden flex flex-col lg:flex-row">
                            <div
                                className={`w-full h-full lg:w-1/3 lg:border-r border-gray-200 p-4 ${selectedChatRooms ? 'hidden lg:block' : ''}`}>
                                <ul className="space-y-4">
                                    {chatRooms?.map((chatRoom, index) => (
                                        <li key={chatRoom._id}
                                            className="cursor-pointer p-4 border border-gray-300 rounded-lg hover:bg-gray-100"
                                            onClick={() => openChat(chatRoom)}>
                                            <div
                                                className="flex items-center justify-between"
                                            >
                                                <div className="flex items-center">
                                                    <div
                                                        className="p-2 w-10 h-10 bg-blue-500 text-white rounded-full flex items-center justify-center">
                                                        {chatRoom?.displayUser?.name
                                                            ? chatRoom?.displayUser?.name
                                                                ?.split(" ")
                                                                .map((x) => x.charAt(0).toUpperCase())
                                                                .join("")
                                                            : ""}
                                                    </div>
                                                    <div className={"ml-3"}>
                                                        <h3 className="text-gray-800 font-medium">
                                                            {chatRoom?.displayUser?.name}
                                                        </h3>
                                                        <p className="text-gray-600 text-sm line-clamp-2">
                                                            {chatRoom.lastChat ? chatRoom.lastChat.message : ""}
                                                        </p>
                                                    </div>
                                                </div>
                                                <span className="text-gray-500 text-xs flex flex-col items-end">
                                                    <span>
                                                        {chatRoom.lastChat ? new Date(chatRoom.lastChat.createdAt).toLocaleDateString('en-US', {
                                                            hour: 'numeric',
                                                            minute: '2-digit',
                                                            hour12: true
                                                        }) : ""}
                                                    </span>
                                                    {chatRoom.totalUnread > 0 ? <span
                                                        className={`py-0.5 px-1.5 w-fit h-fit bg-red-500 text-white rounded-full flex items-center justify-center`}>
                                                        {chatRoom.totalUnread}
                                                    </span> : null}
                                                </span>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            {selectedChatRooms && (
                                <div
                                    className="w-full h-full lg:w-2/3 p-4 flex flex-col">
                                    <div
                                        className="flex items-center justify-between border-b border-gray-200 pb-2 mb-4">

                                        <button onClick={() => setSelectedChatRooms(null)}
                                                className="lg:hidden block p-2 bg-gray-200 text-gray-700 rounded-md">
                                            <IoArrowBack/>
                                        </button>
                                        <div
                                            className="flex items-center">
                                            <Avatar size={50} icon={<UserOutlined className="rounded-full"/>}/>
                                            <h2 className="text-lg font-semibold text-gray-800 ml-3">
                                                {selectedChatRooms.displayUser.name}
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="flex-1 overflow-y-auto space-y-4 max-h-[calc(100%-130px)]"
                                         ref={chatRef}>
                                        {chatMessages.map((msg, index) => (
                                            <div key={index}
                                                 className={`flex ${msg.senderId !== loggedInUser._id ? "justify-start" : "justify-end"}`}>
                                                <div
                                                    className={` ${
                                                        msg.senderId !== loggedInUser._id
                                                            ? "bg-blue-500 text-white"
                                                            : "bg-gray-300"
                                                    } p-3 rounded-lg max-w-3xl`}
                                                >
                                                    <p className={`${msg.senderId === loggedInUser._id ? 'text-gray-800' : ''}`}>{msg.message}</p>
                                                    <span
                                                        className={`${msg.senderId !== loggedInUser._id ? "text-gray-200" : "text-gray-500"} text-xs float-right`}>{new Date(msg.createdAt).toLocaleDateString('en-US', {
                                                        hour: 'numeric',
                                                        minute: '2-digit',
                                                        hour12: true
                                                    })}</span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className={`mt-4 flex items-center`}>
                                        <input
                                            type="text"
                                            placeholder="Type your message..."
                                            value={messageInput}
                                            onChange={(e) => setMessageInput(e.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    sendMessage();
                                                }
                                            }}
                                            className="w-full border border-gray-300 rounded-md p-2 focus:outline-none"
                                        />
                                        <MdOutlineEmojiEmotions
                                            className="text-2xl cursor-pointer ml-2"
                                            onClick={() => setShowPicker((val) => !val)}
                                        />
                                        <div className="flex absolute bottom-10 left-16">
                                            {showPicker && (
                                                <EmojiPicker onEmojiClick={onEmojiClick}/>
                                            )}
                                        </div>

                                        <button
                                            onClick={sendMessage}
                                            className="bg-blue-500 text-white py-2 px-4 rounded-md ml-2">
                                            <IoSend/>
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                        : (
                            <Link className="text-xl border-b-2" to="/login">
                                Login to send message
                            </Link>
                        )}
                </>
            ) : (
                <div className="text-center w-full">
                    <h1 className="text-2xl">No message yet</h1>
                </div>
            )}
        </div>
    );
};

export default Chat;
