import Allusers from "./Alluser";
import Orders from "./Orders";
import Dashboard from "./Dashboard";

import Tags from "./Tags";
import AppliedJobs from "./AppliedJobs";

import React, { useContext, useEffect, useState } from "react";
import {
  OrderedListOutlined,
  ShoppingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Layout, Menu, message, theme } from "antd";
import { useTranslation } from "react-i18next";
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { Footer } from "antd/es/layout/layout";
import Footer1 from "../Landing/Footer1";
import UserDropdown from "../UserDropdown";
import ChatButton from "../Chat/ChatButton";
import { CiShoppingTag } from "react-icons/ci";
import axios from "axios";
import { SupportContext } from "../../Context/AdminContext";
import { UserContext } from "../../Context/UserContext";
import Complaints from "./Complaints";
import { MdOutlineLibraryBooks } from "react-icons/md";

const { Header, Sider, Content } = Layout;

const Admin = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const [openKeys, setOpenKeys] = useState([]);
  const [selectedMenuItem, setSelectedMenuItem] = useState("");
  const {id} = useParams()
  const {setAllTags,allUsers,setAllUsers,setAllJobsSupport} = useContext(SupportContext);
  const { fullprofile } = useContext(UserContext);
  
  const fetchJobs =()=>{
    axios.get(`${process.env.REACT_APP_API_URL}/admin/jobs`,{withCredentials:true}).then((res)=>{
      setAllJobsSupport(res.data.jobs)
    }).catch((err)=>{
      console.log(err)
    })
  }
 
  const fetchUsers = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/admin/users`, { withCredentials: true })
      .then((res) => {
        setAllUsers(res.data.users);
      })
      .catch((err) => console.log(err));
  };

  
 useEffect(() => {
   if (fullprofile?.data?.role === 'USER') {
     navigate('/');
   }
 }, [fullprofile, navigate]);
  
 const FindUsername = allUsers.find(job=>job._id === id)
 console.log(FindUsername,"getname")

  const fetchAllTags =()=>{
    axios.get(`${process.env.REACT_APP_API_URL}/admin/tags`,{withCredentials:true})
    .then((res)=>{
      setAllTags(res.data.tags)
    }).catch((err)=>{
      console.log(err)
      message.error("something wents wrong")
    })
  }

  useEffect(()=>{
    fetchJobs();
    fetchUsers();
    fetchAllTags();
  },[])
  
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const menuItems = [
    {
      key: "support",
      icon: <OrderedListOutlined />,
      label: t("Dashboard"),
      content: <Dashboard />,
      path: "/support/dashboard",
    },
    {
      key: "allusers",
      icon: <UserOutlined />,
      label: t("All Users"),
      content: <Allusers />,
      path: "/support/allusers",
    },
    {
      key: "orders",
      icon: <ShoppingOutlined />,
      label: t("Orders"),
      content: <Orders />,
      path: "/support/orders",
    },
    {
      key: "tags",
      icon: <CiShoppingTag />,
      label: t("Tags"),
      content: <Tags />,
      path: "/support/tags",
    },
    {
      key: "applied",
      icon: <ShoppingOutlined />,
      label: t("applied orders"),
      content: <AppliedJobs />,
      path: "/support/applied-order",
    },
    {
      key: "complaints",
      icon: <MdOutlineLibraryBooks />,
      label: t("Complaints"),
      content: <Complaints />,
      path: "/support/complaints",
    },
  ];

  const onOpenChange = (keys) => {
    const isOrderPage = location.pathname.startsWith("/support/orders");
    setOpenKeys(isOrderPage ? ["orders"] : keys);
  };
 
  
  const findLabelByPath = (path) => {
    const menuItem = menuItems.find((item) => item.path === path);
    return menuItem ? menuItem.label : "";
  };
  useEffect(() => {
    const currentLabel = findLabelByPath(location.pathname);

    if (location.pathname.startsWith("/support/user/")) {
      setSelectedMenuItem(FindUsername?.name);
    } else {
      setSelectedMenuItem(currentLabel);
    }
  }, [location.pathname]);


  useEffect(() => {
    const currentLabel = findLabelByPath(location.pathname);
  
    if (location.pathname.startsWith("/support/user/")) {
      setSelectedMenuItem(FindUsername?.name);
    } else if (location.pathname.startsWith("/support/complaints/")) {
      setSelectedMenuItem("Complaints");
    } else {
      setSelectedMenuItem(currentLabel);
    }
  }, [location.pathname]);
  
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        breakpoint="xxl"
        collapsedWidth="0"
        collapsible
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
      >
        <div className="demo-logo-vertical" />

        <div className="demo-logo-vertical text-white flex flex-col justify-center space-y-3 items-center my-3">
          <Link to="/">
            <img className="h-12" src="../../images/logo.png" alt="" />
            <p className="font-extrabold text-xl text-white">WORY</p>
          </Link>
        </div>

        <Menu
          theme="dark"
          mode="inline"
          style={{ height: "100%", borderRight: 0 }}
          selectedKeys={[
            location.pathname.startsWith("/support/user/") ? "/support/allusers" : 
            location.pathname.startsWith("/support/complaints/") ? "/support/complaints" :
            location.pathname
          ]}
          
                  openKeys={openKeys}
          onOpenChange={onOpenChange}
        >
          {menuItems.map((item) => {
            return (
              <Menu.Item
                key={item.path}
                icon={item.icon}
                onClick={() => navigate(item.path)}
              >
                <Link to={item.path}>{item.label} </Link>
              </Menu.Item>
            );
          })}
        </Menu>
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          <div className=" bg-[#002140] p-4 flex place-content-between">
            <div className="flex space-x-5">
            <p className="font-bold text-xl pt-1 text-white">{selectedMenuItem}</p>
            
            </div>
            <div className="flex space-x-5">
              <UserDropdown />
            </div>
          </div>
        </Header>
      
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          {" "}
          <Outlet />
          <ChatButton />
        </Content>
        <Footer style={{ textAlign: "center" }}>
          <Footer1 />
        </Footer>
      </Layout>
    </Layout>
  );
};

export default Admin;
